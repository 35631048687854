* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	margin: 0;
	font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
a {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
}
