.home_up {
	position: fixed;
	top: 80vh;
	font-size: 40px;
	right: 15px;
	background: #041c3a73;
	color: white;
	padding-right: 10px;
	padding-left: 10px;
	border-radius: 5px;
}
