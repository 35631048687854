.footer {
	background: #041c3a;
	min-height: 250px;
	color: white;
	margin-top: 2rem;
	padding: 2rem;
}
.footer_form {
	display: flex;
	align-items: center;
}
.footer_content {
	padding-top: 2rem;
}
.footer_form > input {
	width: 70%;
	box-shadow: inset 0 0 3px black;
}
.footer_form > button {
	border: none;
	background: none;
	font-size: 35px;
	color: white;
	margin-left: 10px;
}
.footer_icon {
	margin-right: 20px;
}

.footer_social {
	display: flex;
	justify-content: space-evenly;
	margin-top: 5rem;
}
.footer_right span {
	font-size: 2rem;
	font-weight: lighter;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
}
.footer_right {
	text-align: center;
	margin-top: 3rem;
}
