.services {
	margin-top: 3rem;
}

.service {
	width: 300px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.411);
	margin: 10px;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
}
.service:hover {
	box-shadow: 0 0 10px #444141a6;
}
.service:hover .service_img {
	transform: scale(1.1);
}
.service > h4 {
	margin-top: 10px;
}
.service_img {
	width: 100%;
	object-fit: contain;
	padding: 2px;
	transition: all 0.3s ease-in-out;
}

.service_container {
	display: flex;
	justify-content: center;
}
.service_info {
	margin-top: 20px;
	padding: 10px;
}
.service_info > button {
	margin-top: 20px;
	width: -moz-fit-content;
	width: fit-content;
	font-family: sans-serif;
	font-size: 20px;
	padding: 0 5px;
	float: right;
	background: none;
	outline: none;
	border-top: 0;
	border-left: 0;
	border-right: 0.5px solid black;
	border-bottom: 0.5px solid black;
	margin-bottom: 20px;
	transition: all ease 0.3s;
	border-radius: 5px;
}

.service_info > button:hover {
	background: red;
	border: none;
	box-shadow: 0 0 10px #826767;
	color: white;
}
@media screen and (max-width: 800px) {
	.services {
		margin-top: 20vh;
	}

	.service_container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.service {
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 10px;
	}
}
